import React from "react";

import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider, createStorage, cookieStorage } from "wagmi";
import { mainnet, arbitrum, shape, base } from "wagmi/chains";
import Routers from "./Routes";

const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "ddb471e501d5b6a63b9c6fe263242a27";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, arbitrum, shape, base] as const;
const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
});

// 3. Create modal
createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

const App = () => (
  <WagmiProvider config={wagmiConfig}>
    <ThemeProvider theme={darkTheme}>
      <QueryClientProvider client={queryClient}>
        <Routers />
      </QueryClientProvider>
    </ThemeProvider>
  </WagmiProvider>
);

export default App;
